/*
 * The logout() method exposed by Auth0Context clears the application session and redirects to the Auth0 /v2/logout endpoint to clear the 
 * Auth0 session. 
 * As with the login methods, we can pass an object argument to logout() to define parameters for the /v2/logout call. 
 * This process is fairly invisible to the user. See LogoutOptions for more details
 * https://auth0.com/blog/complete-guide-to-react-user-authentication/#:~:text=the%20user.%20See-,LogoutOptions,-for%20more%20details
 * 
 * However, if we were to deploy our React application to production, we need to add the production logout URL to the "Allowed Logout URLs" 
 * list and ensure that Auth0 redirects our users to that production URL and not localhost. 
 * Setting returnTo to window.location.origin will do just that
 *
 *
 * Here, we pass the returnTo option to specify the URL where Auth0 should redirect your users after they logout. 
 * Right now, we are working locally, and our Auth0 application's "Allowed Logout URLs" point to http://localhost:3000
 */

import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

export const Logout = () => {
    const { logout, isAuthenticated } = useAuth0();

    return (
        <>
            { isAuthenticated ? (
                <IconButton onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} title="Sign Out!" color="secondary">
                    <LogoutIcon />
                </IconButton>) : null }
        </>
    );
};