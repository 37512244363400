import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material"
import PropTypes from 'prop-types';

import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';


const TikTokIcon = ({ color = "#000000", opacity="100%" }) => {
    return (
        <svg fill={color} fillOpacity={opacity} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="1.5rem" height="1.5rem">
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
        </svg>
    );
};

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
    socialContainer: {
        width: '100%',
        position: 'relative'
    },
    socialThumbnail: {
        width: '100%',
        mt: 1,
        filter: "brightness(20%)",
        display: 'block'
    },
    socialTitle: {
        position: 'absolute',
        bottom: 0,
        color: 'neutral.light',
        left: 0,
        right: 0,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1rem',
        mb: 2
    },
    socialTimeLabel: {
        color: 'neutral.normal',
        mt: 2
    },
    socialStatsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        mt: 2,
    },
    cardAction: {
        mt: 2
    }
}

export const Social = ({ data }) => {

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Typography variant="cardTitle">Social Media</Typography>
                <Box sx={styles.socialContainer} >
                    <Box sx={styles.socialThumbnail} component={'img'} src={''} />
                    <Typography sx={styles.socialTitle}>Player Hudl</Typography>
                </Box>
                <Box sx={styles.socialStatsRow}>
                    <TwitterIcon color="disabled" /> 
                    <InstagramIcon color="disabled" /> 
                    <FacebookIcon color="disabled" /> 
                    <YouTubeIcon color="disabled" /> 
                    <TikTokIcon color="grey" opacity="0.4" />
                </Box>
            </CardContent>
        </Card>
    );
};

Social.propTypes = {
    data: PropTypes.object.isRequired,
};