import React from "react";
import { Badge, Card, CardContent, Typography } from "@mui/material"
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


export const Schools = ({ data }) => {
    const offers = data.offers.split(",").filter((offer) => offer.trim() !== "");

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Badge badgeContent={offers.length} color="primary">
                    <Typography variant="cardTitle">Offers</Typography>
                </Badge>
                <hr />
                
                <Stack direction="row" spacing={{ xs: 1, sm: 2 }} useFlexGap flexWrap="wrap">
                {
                    offers.sort().map((offer) => (
                        <Chip avatar={<Avatar>{offer.trim().charAt(0)}</Avatar>} label={offer.trim()} variant="outlined" color="primary" />
                    ))
                }
                </Stack>
            </CardContent>
        </Card>
    );
};

Schools.propTypes = {
    data: PropTypes.object.isRequired,
};