import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material"
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';


export const Notes = ({ data }) => {
  return (
    <Card sx={{mt: 2}}>
        <CardContent>
            <Typography variant="cardTitle">General Notes</Typography>
            <hr />

            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%', }, }} noValidate autoComplete="off">
                <TextField id="standard-multiline-static" label="Multiline" multiline rows={8} defaultValue={data.notes} variant="standard" />
            </Box>
        </CardContent>
    </Card>
  );
}

Notes.propTypes = {
    data: PropTypes.object.isRequired,
};