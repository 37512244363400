import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

let theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: "#679436",
        },
        secondary: {
            main: "#EBF2FA",
        },
        tertiary: {
            main: "#05668D",
        },
        quaternary: {
            main: "#679436",
        },
        quinary: {
            main: "#A5BE00",
        },
        senary: {
            main: "#CCDBDC",
        },
        neutral: {
            light: grey[50],
            medium: grey[200],
            normal: grey[700],
            main: grey[900],
        },
        background: {
            default: '#EBF2FA',
            paper: '#FFFFFF',
        },
        green: {
            main: "#136f63",
        },
    },
});

theme = createTheme(theme, {
    typography: {
        link: {
            fontSize: '0.8rem',
            [theme.breakpoints.up('md')]: {
                fontSize: '0.9rem',
            },
            fontWeight: 500,
            color: theme.palette.primary.main,
            display: 'block',
            cursor: 'pointer',
        },
        cardTitle: {
            fontSize: '1.2rem',
            display: 'block',
            fontWeight: 500
        },
        card: {
            mt: 4,
        },
        h6: {
            fontSize: '1rem',
        },
        h7: {
            fontSize: '0.8rem', 
        },
        h8: {
            fontSize: '0.7rem', 
        },
    },
});

export default theme;