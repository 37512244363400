import React from 'react';
import { AppBar, Box, IconButton, Toolbar, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Logout } from '../../components/form/button/logout';
import { useProSidebar } from 'react-pro-sidebar';


/** @type {import("@mui/material").SxProps} */
const styles = {
    navBar: {
        bgcolor: 'primary.main'
    },
    appLogo: {
        borderRadius: 2,
        width: 35,
        marginLeft: 2,
        cursor: 'pointer'
    }
}

export const Navbar = () => {
    const { collapseSidebar, toggleSidebar, broken } = useProSidebar();
    
    return (
        <AppBar position="sticky" sx={styles.navBar}>
            <Toolbar>
                <IconButton onClick={ () => broken ? toggleSidebar() : collapseSidebar() } color="secondary">
                    <MenuIcon />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <Logout />
            </Toolbar>
        </AppBar>
    );
}
