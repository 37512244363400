/*
 * loginWithRedirect() is a method exposed by the Auth0Context. 
 * Calling this method prompts a user to authenticate and provide consent for our React application to access certain data on behalf of that user. 
 * In your current architecture, this means that our React application redirects the user to the Auth0 Universal Login page to carry out the 
 * authentication process
 */

/*
 * We can pass a configuration object to loginWithRedirect() to customize the login experience. 
 * For example, we can pass options to redirect users to an Auth0 Universal Login page optimized for signing up for your React application. 
 * See RedirectLoginOptions for more details on these options
 * https://auth0.github.io/auth0-react/interfaces/auth0_context.redirectloginoptions.html
 */

import React from "react";
import { Button } from "@mui/material";
import { Auth0Lock } from "auth0-lock";


export const Welcome = () => {
    const lock = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID, process.env.REACT_APP_AUTH0_DOMAIN, {
        auth: {
            redirectUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        },
        theme: {
            logo: 'https://icons.iconarchive.com/icons/icons8/windows-8/256/Photo-Video-Sports-Mode-icon.png',
            primaryColor: '#024d15'
        },
        languageDictionary: {
            title: 'SMSB Recruiting Services',
            signUpTitle: 'SMSB Recruiting Services',
        },
        closable: false,
        autofocus: true,
        initialScreen: 'signUp',
        additionalSignUpFields: [
            {
                name: "given_name",
                placeholder: "Enter your first name",
                storage: "root",
                validator: function(value) {
                    return {
                        valid: value.length >= 1,
                        hint: 'First name is required!'
                    };
                }
            },
            {
                name: "family_name",
                placeholder: "Enter your last name",
                storage: "root",
                validator: function(value) {
                    return {
                        valid: value.length >= 1,
                        hint: 'Last name is required!'
                    };
                }
            },
            {
                name: "nickname",
                type: "select",
                storage: "root",
                placeholder: "choose your region",
                options: [
                    {value: "canada", label: "Canada"},
                    {value: "eastcoast", label: "Eastcoast"},
                    {value: "midwest", label: "Midwest"},
                    {value: "national", label: "National"},
                    {value: "southeast", label: "Southeast"},
                    {value: "southwest", label: "Southwest"},
                    {value: "westcoast", label: "West Coast"},
                ],
                prefill: "national",
            },
        ]
    });

    // Listen for the authenticated event and get profile
    lock.on('authenticated', (authResult) => {
        lock.getUserInfo(authResult.accessToken, (error, profile) => {
            if (error) {
                // Handle error
                return;
            }

            localStorage.setItem('accessToken', authResult.accessToken);
            localStorage.setItem('coachProfile', JSON.stringify(profile));
        });
    });

    return ( <Button className="absolute-center" onClick={() => lock.show()} tabIndex={0} component="button">Welcome Click Here to Enter</Button> );
}
