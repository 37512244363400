/*
 * The withAuthenticationRequired Higher-Order Component (HOC) takes the following arguments:
 * The component that you want to protect.
 * A configuration object to customize the authentication flow, WithAuthenticationRequiredOptions. 
 * This object takes the following optional properties:
 *      loginOptions: It behaves exactly like the configuration options you can pass to loginWithRedirect() to customize the login experience.
 *      returnTo: It lets you specify a path for React to redirect a user after the login transaction that the user triggered in this component 
 *          completes.
 *      onRedirecting: It renders a component while your React application redirects the user to the login page.
 */

import React from 'react';
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { Loading } from '../../ui/loading/Loading';

export const AuthenticationGuard = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (<Loading />),
    });

    return <Component />;
};