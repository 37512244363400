import React from 'react'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './assets/css/modal-video.scss';

import theme from './config/theme';
import { SideNav } from './layouts/sidenav';
import { Navbar } from './layouts/navbar';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './router';

/** @type {import("@mui/material").SxProps} */
const styles = {
  container: {
    display: 'flex',
    bgcolor: 'background.default',
    height: 'calc(100% - 64px)'
  },
  mainSection: {
    p: 4,
    width: '100%',
    height: '100%',
    overflow: 'auto',
  }
}

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <ProSidebarProvider>
          <CssBaseline />
          <BrowserRouter>
            <Navbar />
            <Box sx={styles.container}>
                <SideNav />
                <Box component={'main'} sx={styles.mainSection}>
                  <AppRoutes /> 
                </Box>
            </Box>
          </BrowserRouter>
        </ProSidebarProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App;
