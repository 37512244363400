import React from "react";
import { useLocation } from "react-router-dom";
import { Avatar, Box, IconButton, Modal, Typography } from "@mui/material";
import Rating from '@mui/material/Rating';
import { Info } from "../../components/player/info";
import { Schools } from "../../components/player/schools";
import { Social } from "../../components/player/social";
import { Notes } from "../../components/player/notes";


/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
    pageTitle: {
        mb: 2,
        fontWeight: 'bold'
    },
    columnsContainer: {
        columns: '280px 3',
        maxWidth: 1400
    },
    columnsContainerGallery: {
        columns: '1400px 1',
        maxWidth: 1400,
        maxHeight: 500,
        mt: 2
    },
    item: {
        mb: 2,
        
    },
    divider: {
        my: 2
    },
    videoStatsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        mt: 2,
        '&:hover': {
            color: 'primary.main',
            cursor: 'pointer'
        }
    },
    cardAction: {
        mt: 2
    },
    ideaContent: {
        display: 'flex',
    },
    ideaImage: {
        width: 80,
        alignSelf: 'center',
        ml: 5
    },
    ideaQuestion: {
        fontSize: '0.9rem',
        fontWeight: 500,
        my: 2
    },
    avatar: {
        width: '30px',
        height: 'auto',
        marginRight: 1,
        backgroundPosition: "top center",
    },
    postStats: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridAutoRows: '25px'
    },
    postAuthorSection: {
        display: 'flex',
        alignItems: 'center',
        my: 3
    },
    postMeta: {
        mr: 1,
        fontSize: '0.8rem',
        color: 'neutral.normal'
    },
    videoThumbnail: {
        width: 80,
        ml: 'auto'
    },
    commentRow: {
        display: 'flex',
        alignItems: 'flex-start',
        mt: 2
    },
    commentDetailsSection: {
        display: 'flex',
        alignItems: 'center',
    },
    commentText: {
        fontSize: '0.8rem',
        mt: 0.5,
        mr: 2
    },
    insiderImage: {
        width: '100%',
        mt: 1
    },
    imageModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }
}

export const Profile = () => {
    const location = useLocation();
    const data = location.state.data;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box>
            <IconButton>
                <Avatar alt={`${data.firstname} ${data.lastname}`} src={data.image} style={{ margin: "10px", width: "90px", height: "100px", border: "2px solid #fff" }} onClick={handleOpen} />
            </IconButton>
            <Modal open={open} onClose={handleClose}>
                <Box sx={styles.imageModal}>
                    <img src={data.image} alt="profile" style={{ width: "100%" }} />
                </Box>
            </Modal>

            <Typography sx={styles.pageTitle} variant="h5">{data.firstname} {data.lastname}</Typography>
            <Typography sx={styles.pageTitle} variant="h6">{data.pos}, {data.currenthighschool} ({data.state})</Typography>

            <Rating name="read-only" value={data.stars} readOnly />

            <Box sx={styles.columnsContainer}>
                <Info sx={styles.item} data={data} />
                <Schools sx={styles.item} data={data} />
                <Social sx={styles.item} data={data} />
                <Notes sx={styles.item} data={data} />
            </Box>
        </Box>
    );
};