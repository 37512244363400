import React from 'react';
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { AuthenticationGuard } from '../components/security/authentication/AuthenticationGuard';
import { Home } from '../pages/home';
import { Lounge } from '../pages/lounge';
import { Profile } from '../pages/profile';
import { NotFound } from '../pages/notfound';
import { Loading } from '../components/ui/loading/Loading';

export const AppRoutes = () => {
    const { isLoading } = useAuth0();
    if (isLoading) {
        return <Loading />;
    }

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/lounge" element={<AuthenticationGuard component={Lounge} />} />
            <Route path="/profile/:profile" element={<AuthenticationGuard component={Profile} />} />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};