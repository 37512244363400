import React from 'react';
import { Avatar, Box, Typography, } from '@mui/material';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar'; 
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { useProSidebar } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import theme from '../../config/theme';
import { useAuth0 } from "@auth0/auth0-react";

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
    avatarContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: 'column',
        my: 5
    },
    avatar: {
        width: '40%',
        height: 'auto'
    },
    channel: {
        mt: 1
    }
}

export const SideNav = () => {
    const { collapsed } = useProSidebar();
    const { isAuthenticated, user } = useAuth0();

    return (
        <Sidebar style={{ height: "100%", top: "auto" }} breakPoint="md" backgroundColor={theme.palette.senary.main}>
            { isAuthenticated ?
                (<Box sx={styles.avatarContainer}>
                    { user.name ?
                        <Avatar alt="Welcome Coach!" sx={styles.avatar} src={user.picture} />
                        :
                        <Avatar alt="Welcome Coach!">{user.name.charAt(0).toUpperCase()}</Avatar>
                    }
                    { !collapsed ? <Typography variant="body2" sx={styles.channel}>{user.name}</Typography> : null }
                </Box>) : null }

            { isAuthenticated ? (
                <Menu menuItemStyles={{
                    button: ({ active }) => {
                        return {
                            backgroundColor: active ? theme.palette.neutral.medium : undefined,
                        };
                    },
                }}>
                    <MenuItem active={window.location.pathname === "/lounge"} component={<Link to="/lounge" />} icon={<RecentActorsIcon />}>
                        <Typography variant="body2">Lounge</Typography>
                    </MenuItem>
                </Menu>) : null }
        </Sidebar>
    );
}
