// What is happening within Auth0ProviderWithHistory?
// reference: https://auth0.com/blog/complete-guide-to-react-user-authentication/
// reference: https://auth0.com/docs/quickstart/spa/react/02-calling-an-api#set-up-the-auth0-service
// well first we are importing React and useHistory from react-router-dom
import React from "react";

// then we are importing Auth0Provider from @auth0/auth0-react
// because we need the Auth0 React SDK to connect with the correct Auth0 Application to process authentication...
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
    // ...as such, we need Auth0 Domain and Client ID to configure the Auth0Provider
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

    return (
        // That's it! Wrapping any component tree with Auth0ProviderWithHistory will give it access to the Auth0Context
        <Auth0Provider 
            domain={domain} 
            clientId={clientId} 
            authorizationParams={{
                redirect_uri: redirectUri,
            }}> 
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;