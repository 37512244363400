import React, { useEffect, useState } from 'react';
import axios from "axios";
import Rating from '@mui/material/Rating';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const baseURL = `https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_SPREADSHEET_ID}/values:batchGet?ranges=A3:AA900&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
const columns = [
	{ field: 'id', headerName: 'ID', width: 80, },
	{ field: 'image', headerName: 'Profile Pic', width: 150, },
    { field: 'firstname', headerName: 'First name', width: 150, renderCell: (params) => <Link style={{ textDecoration: 'none', color: '#05668D' }} to={`/profile/${params.row.id}`} state={{ data: params.row }}>{params.value}</Link>, },
    { field: 'lastname', headerName: 'Last name', width: 180, renderCell: (params) => <Link style={{ textDecoration: 'none', color: '#05668D' }} to={`/profile/${params.row.id}`} state={{ data: params.row }}>{params.value}</Link>, },
    { field: 'pos', headerName: 'Position', width: 75, },
    { field: 'gpa', headerName: 'GPA', width: 75, },
    { field: 'currenthighschool', headerName: 'School', width: 200, },
    { field: 'state', headerName: 'State', width: 80, },
    { field: 'region', headerName: 'Region', width: 150, },
    { field: 'gradyear', headerName: 'Class', type: 'number', width: 80, valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: "decimal", maximumFractionDigits: 0 }).format(value).replace(/,/g,""), },  
    { field: 'ht', headerName: 'Height', width: 80, },
    { field: 'wt', headerName: 'Weight', type: 'number', width: 80, },
    { field: 'offers', headerName: 'Offers', width: 200, },
    { field: 'typeofplayer', headerName: 'Type of Player', width: 200, },
    { field: 'notes', headerName: 'Notes', width: 200, },
    { field: 'wingspan', headerName: 'Wingspan', width: 200, },
    { field: 'handsize', headerName: 'Hand Size', width: 200, },
    { field: 'shoesize', headerName: 'Shoe Size', width: 200, },
    { field: '40yarddash', headerName: '40-Yard Dash', width: 200, },
    { field: 'shortshuttle', headerName: 'Short Shuttle', width: 200, },
    { field: '10yarddash', headerName: '10-Yard Dash', width: 200, },
    { field: 'broadjump', headerName: 'Broad Jump', width: 200, },
    { field: 'ldrill', headerName: 'L-Drill', width: 200, },
    { field: 'hudl', headerName: 'Hudl', width: 200, },
    { field: 'hudlvidurl', headerName: 'Hudl Video', width: 200, },
    { field: 'campvidurl', headerName: 'Camp Video', width: 200, },
    { field: 'stars', headerName: 'Stars', width: 200, renderCell: (params) => <Rating name="read-only" value={params.value} readOnly />, },
];

function func(key, val){
    return Object.assign({}, ...key.map((k, i) => ({[k]: val[i]})));
}

export const Lounge = () => {
    const [players, setPlayers] = useState([]);
    const { user } = useAuth0();

    let filter_val = "";
    if (user.nickname.toLowerCase() === "southeast") {
        filter_val = "Southeast";
    } else if (user.nickname.toLowerCase() === "southwest") {
        filter_val = "Southwest";
    } else if (user.nickname.toLowerCase() === "westcoast") {
        filter_val = "West Coast";
    } else if (user.nickname.toLowerCase() === "midwest") {
        filter_val = "Midwest";
    } else if (user.nickname.toLowerCase() === "eastcoast") {
        filter_val = "Eastcoast";
    } else if (user.nickname.toLowerCase() === "canada") {
        filter_val = "Canada";
    }

    useEffect(() => {
        axios.get(baseURL)
            .then((response) => {
                const keys = ['id', 'image', 'firstname', 'lastname', 'pos', 'gpa', 'currenthighschool', 'state', 'region', 'gradyear', 'ht', 'wt', 'offers', 'typeofplayer', 'notes', 'wingspan', 'handsize', 'shoesize', '40yarddash', 'shortshuttle', '10yarddash', 'broadjump', 'ldrill', 'hudl', 'hudlvidurl', 'campvidurl', 'stars'];
                const _data = response.data.valueRanges[0].values;

                setPlayers(_data.map((val) => func(keys, val)));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    
    return (
        <Box>
            <DataGrid columns={columns} rows={players}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 15, },
                    },
                    filter: {
                        filterModel: {
                            items: [{ field: 'region', operator: 'equals', value: filter_val},],
                        },
                    },
                    columns: {
                        columnVisibilityModel: { 
                            id: false, 
                            // region: false,
                            // image: false, 
                            // gpa: false, 
                            // offers: false, 
                            hudl:false, 
                            hudlvidurl: false, 
                            campvidurl: false, 
                            // typeofplayer: false, 
                            // notes: false, 
                            // wingspan: false, 
                            // handsize: false, 
                            // shoesize: false, 
                            // shortshuttle: false, 
                            // broadjump: false, 
                            // ldrill: false, 
                            // "40yarddash": false, 
                            // "10yarddash": false, 
                        }
                    },
                }} pageSizeOptions={[15]} disableRowSelectionOnClick disableColumnFilter slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }} />
        </Box>
    );
};