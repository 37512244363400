import React, { useState } from "react";
import { Box, Card, CardContent, Divider, Link, Typography } from "@mui/material"
import PropTypes from "prop-types";
import ModalVideo from "react-modal-video";


/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
    infoContainer: {
        width: '100%',
        position: 'relative'
    },
    infoThumbnail: {
        width: '100%',
        mt: 1,
        filter: "brightness(20%)",
        display: 'block'
    },
    infoTitle: {
        position: 'absolute',
        bottom: 0,
        color: 'neutral.light',
        left: 0,
        right: 0,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1rem',
        mb: 2
    },
    infoTimeLabel: {
        color: 'neutral.normal',
        mt: 2
    },
    infoStatsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        mt: 2,
    },
    infoStatsRow2: {
        display: 'flex',
        mt: 2,
    },
    cardAction: {
        mt: 2
    }
}


export const Info = ({ data }) => {
    const [isOpen, setOpen] = useState(false);
    const [isOpenCustom, setOpenCustom] = useState(false);

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Typography variant="cardTitle">Basic Info</Typography>
                <hr />
                <Box sx={styles.infoStatsRow2}>
                    {
                        data.hudlvidurl ? 
                            <>
                                <ModalVideo channel='custom' url={data.hudlvidurl} isOpen={isOpen} onClose={() => setOpen(false)} />
                                <Link underline="hover" onClick={()=> setOpen(true)} variant="body2" style={{ cursor: 'pointer' }}>View Hudl Video</Link>
                            </>
                             : <Typography sx={styles.infoTitle}>Hudl Video Not Available</Typography>
                    }
                    <Divider variant="middle" />
                    {
                        data.hudl ? 
                            <Link underline="hover" href={data.hudl} variant="body2" target="_blank">View Hudl Profile</Link> : 
                            <Typography sx={styles.infoTitle}>Hudl Profile Not Available</Typography>
                    }
                    <Divider variant="middle" />
                    {
                        data.campvidurl ? 
                            <>
                                <ModalVideo channel='youtube' videoId={data.campvidurl.split('v=')[1]} youtube={{ mute: 1, autoplay: 1 }} isOpen={isOpenCustom} onClose={() => setOpenCustom(false)} />
                                <Link underline="hover" onClick={()=> setOpenCustom(true)} variant="body2" style={{ cursor: 'pointer' }}>View Camp Video</Link>
                            </>
                             : <Typography sx={styles.infoTitle}>Camp Video Not Available</Typography>
                    }
                </Box>

                <Box sx={styles.infoStatsRow}>
                    <Typography variant="h6">Class</Typography>
                    <Typography variant="h7">{data.gradyear}</Typography>
                </Box>
                <Divider />
                <Box sx={styles.infoStatsRow}>
                    <Typography variant="h6">Height</Typography>
                    <Typography variant="h7">{data.ht}</Typography>
                </Box>
                <Divider />
                <Box sx={styles.infoStatsRow}>
                    <Typography variant="h6">Weight</Typography>
                    <Typography variant="h7">{data.wt}Lbs</Typography>
                </Box>
                <Divider />
                <Box sx={styles.infoStatsRow}>
                    <Typography variant="h6">Region</Typography>
                    <Typography variant="h7">{data.region}</Typography>
                </Box>
                <Divider />
                <Box sx={styles.infoStatsRow}>
                    <Typography variant="h6">Wingspan</Typography>
                    <Typography variant="h7">{data.wingspan}</Typography>
                </Box>
                <Divider />
                <Box sx={styles.infoStatsRow}>
                    <Typography variant="h6">Shoe Size</Typography>
                    <Typography variant="h7">{data.shoesize}</Typography>
                </Box>
                <Divider />
                <Box sx={styles.infoStatsRow}>
                    <Typography variant="h6">Hand Size</Typography>
                    <Typography variant="h7">{data.handsize}</Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

Info.propTypes = {
    data: PropTypes.object.isRequired,
};